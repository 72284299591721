import { graphql } from 'gatsby'
import React from 'react'
import { LANGUAGE, TEMPLATE_TYPES } from '../common/constants'
import useSeo from '../hooks/useSeo'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import Main from '../modules/Main'

const DATA_MAP = {
  title: ['structuredData', 'headline'],
  description: ['structuredData', 'description', 'description'],
  image: ({ image }) => {
    return image?.fluid?.src || image?.gatsbyImageData?.images?.fallback?.src
  },
  altText: ['structuredData', 'altText'],
  breadcrumb: ['postType'],
  category: ['category'],
}

const getLayout = (data, pageContext, helmetProps, rest) => {
  const { headline, description } = pageContext
  const blogs = data.edges.map((e) => {
    const { node } = e
    return {
      ...node,
    }
  })

  return [
    {
      __typename: LAYOUT_TYPES.BLOGS,
      title: headline ? headline : LANGUAGE.blogs_template_description,
      description: description ? description : '',
      blogs: blogs,
      ...rest,
    },
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
    },
  ]
}

const BlogsArchive = (props) => {
  const { pageContext } = props
  const {
    location: { pathname },
  } = props
  let pageNumber = pathname.split('/').pop()

  // Add 1 to pageNumber value
  pageNumber = isNaN(pageNumber) ? null : parseInt(pageNumber)

  const {
    data: { allContentfulPosts },
    ...rest
  } = props

  const url = props.location.href
  const seoProps = useSeo({ ...pageContext, pageNumber }, DATA_MAP, TEMPLATE_TYPES.POST_ARCHIVE)

  const layout = getLayout(allContentfulPosts, pageContext, seoProps, rest)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
    </Main>
  )
}

export default BlogsArchive

export const blogPostQuery = graphql`
  query Posts($skip: Int!, $limit: Int!, $slug: String, $postType: String) {
    allContentfulPosts(
      sort: { order: DESC, fields: date }
      skip: $skip
      limit: $limit
      filter: { category: { elemMatch: { slug: { eq: $slug } } }, postType: { name: { eq: $postType } } }
    ) {
      edges {
        node {
          ...PostArchive
        }
      }
    }
  }
`
